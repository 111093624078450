// src/components/pages/opt-out/index.js
import React from "react"

const OptIn = () => {
  return (
    <div>
      <h1>Opt-In Page</h1>
      <p>This is the opt-in page.</p>
    </div>
  )
}

export default OptIn
